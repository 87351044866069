/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import DatePicker from 'react-datepicker';
import {TextField} from "@mui/material";
import dayjs from "dayjs";
import 'react-datepicker/dist/react-datepicker.css';
import {fetchData, fetchList} from "../../api/fetchData";
import SpinAnimation from "../Widget/SpinAnimation";
import {postData} from "../../api/postData";
import {useNavigate} from "react-router-dom";


export default function AppointmentForm() {
  const navigate = useNavigate();
  const [selectedGender, setSelectedGender] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [dateStr, setDateStr] = useState("");
  const [surname, setSurname] = useState("");
  const [otherNames, setOtherNames] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [groupedServices, setGroupedServices] = useState({});
  const [selectedServiceCategory, setSelectedServiceCategory] = useState("");
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");

  const onDateChange = (dt) => {
    setIsClicked(true);
    setDate(dt);
    setDateStr(dayjs(dt).format('YYYY-MM-DD'));
  };

  async function onSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      if (e.target.checkValidity()) {
        const data = {
          surname,
          other_names: otherNames,
          email,
          phone_number: mobile,
          gender : selectedGender,
          service_id: selectedService,
          appointment_start_date : dayjs(dateStr + " " + startTime).toISOString(),
          appointment_end_date : dayjs(dateStr + " " + endTime).toISOString()
        };
        await postData("create-appointment", "", data);
        alert("Appointment created successfully. Check your email for the next steps");
        navigate("/");
      }
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
  }

  // Render services
  useEffect(() => {
    async function loadCategories() {
      try {
        const apiData = await fetchList("service-category", "", false);
        setServiceCategories(apiData.data);
      } catch (e) {
        console.log(`Unable to fetch categories: ${e.message}`);
      }
    }
    async function loadServices() {
      try {
        const apiData = await fetchList("service", "", false);
        const groupedServices = apiData.data.reduce((acc, service) => {
          const category = service.service_category;
          // If the category key doesn't exist, initialize it as an empty array
          if (!acc[category]) {
            acc[category] = [];
          }
          // Add the current service to the category array
          acc[category].push(service);
          return acc;
        }, {});
        setGroupedServices(groupedServices);
      } catch (e) {
        console.log(`Unable to fetch services: ${e.message}`);
      }
    }
    Promise.all([loadCategories(), loadServices()]);
  }, []);

  useEffect(() => {
    if (selectedServiceCategory) {
      const lst = groupedServices[selectedServiceCategory];
      if (lst) {
        setServices(lst);
      }
    }
  }, [selectedServiceCategory, groupedServices]);

  return (
    <form
      onSubmit={onSubmit}
      className="row"
      id="bookAppointment"
    >
      <p className={"h4 mb-4"}>Personal Details</p>
      <div className="col-12 col-md-6 col-xl-4">
        <label className="cs_input_label cs_heading_color">Surname</label>
        <input
          type="text"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
          required
          className="cs_form_field"
          placeholder=""
        />
        <div className="cs_height_42 cs_height_xl_25"/>
      </div>
      <div className="col-12 col-md-6 col-xl-4">
        <label className="cs_input_label cs_heading_color">Other Names</label>
        <input
          type="text"
          value={otherNames}
          onChange={(e) => setOtherNames(e.target.value)}
          className="cs_form_field"
          placeholder=""
          required
        />
        <div className="cs_height_42 cs_height_xl_25"/>
      </div>
      <div className="col-12 col-md-6 col-xl-4">
        <label className="cs_input_label cs_heading_color">
          Gender
        </label>
        <div>
          <select
            className={"cs_form_field"}
            required
            value={selectedGender}
            onChange={(e) => setSelectedGender(e.target.value)}
          >
            <option disabled value="">Select gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
          <div className="cs_height_42 cs_height_xl_25"/>
        </div>
      </div>
      <div className="col-12 col-md-6 col-xl-4">
        <label className="cs_input_label cs_heading_color">Phone Number</label>
        <input
          type="text"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          className="cs_form_field"
          placeholder=""
          required
        />
        <div className="cs_height_42 cs_height_xl_25"/>
      </div>
      <p className="h4 mb-4">Appointment Details</p>
      <div className="col-12 col-md-6 col-xl-4">
        <label className="cs_input_label cs_heading_color">
          Email
        </label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="cs_form_field"
          placeholder=""
        />
        <div className="cs_height_42 cs_height_xl_25"/>
      </div>
      <div className="col-12 col-md-6">
        <label className="cs_input_label cs_heading_color">
          Service Category
        </label>
        <div>
          <select
            className={"cs_form_field"}
            required
            value={selectedServiceCategory}
            onChange={(e) => setSelectedServiceCategory(e.target.value)}
          >
            <option disabled value="">Select a category</option>
            {
              serviceCategories?.map((category) => {
                return (<option key={category.id} value={category.name}>{category.name}</option>);
              })
            }
          </select>
          <div className="cs_height_42 cs_height_xl_25"/>
        </div>
      </div>
      {
        selectedServiceCategory ?
          <div className="col-12 col-md-6">
            <label className="cs_input_label cs_heading_color">
              Service
            </label>
            <div>
              <select
                className={"cs_form_field"}
                required
                value={selectedService}
                onChange={(e) => setSelectedService(e.target.value)}
              >
                <option disabled value="">Select a service</option>
                {
                  services?.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name} (KES. {Number(item.price).toLocaleString()})
                      </option>
                    );
                  })
                }
              </select>
              <div className="cs_height_42 cs_height_xl_25"/>
            </div>
          </div> : null
      }
      <div className="col-12 col-md-6 col-xl-4">
        <>
          <label className="cs_input_label cs_heading_color">
            Appointment Date
          </label>
          <DatePicker
            className="form-control"
            required
            selected={date}
            onChange={onDateChange}
            suffixIcon={null}
            style={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: isClicked ? '#2E37A4' : '2px solid rgba(46, 55, 164, 0.1)',
                '&:hover': {
                  borderColor: state.isFocused ? 'none' : 'none',
                },
              })
            }}
          />
        </>
        <div className="cs_height_42 cs_height_xl_25"/>
      </div>
      <div className="col-12 col-md-6 col-xl-4">
        <label className="cs_input_label cs_heading_color">
          Start Time
        </label>
        <div className="">
          <TextField
            className="form-control"
            id="outlined-controlled"
            type="time"
            required
            value={startTime}
            onChange={(event) => {
              setStartTime(event.target.value);
            }}
          />
        </div>
        <div className="cs_height_42 cs_height_xl_25"/>
      </div>
      <div className="col-12 col-md-6 col-xl-4">
        <label className="cs_input_label cs_heading_color">
          End Time
        </label>
        <TextField
          className="form-control"
          id="outlined-controlled"
          type="time"
          required
          value={endTime}
          onChange={(event) => {
            setEndTime(event.target.value);
          }}
        />

        <div className="cs_height_42 cs_height_xl_25"/>
      </div>

      <div className="col-lg-12">
        {
          loading ? <SpinAnimation/> :
            <button className="cs_btn cs_style_1">
              <span>Submit</span>
              <i>
                <img src="/images/icons/arrow_white.svg" alt="Icon"/>
                <img src="/images/icons/arrow_white.svg" alt="Icon"/>
              </i>
            </button>
        }
      </div>
    </form>
  );
}
