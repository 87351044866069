import React from 'react';
import Section from '../Section';
import GallerySection from '../Section/GallerySection';
import { pageTitle } from '../../helpers/PageTitle';
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import HeroStyle5 from "../Hero/HeroStyle5";

const galleryData = [
  { imgUrl: '/images/about/gallery-1.jpeg' },
  { imgUrl: '/images/about/gallery-2.jpg' },
  { imgUrl: '/images/about/gallery-3.jpg'  },
  { imgUrl: '/images/about/gallery-4.jpg'  },
];


export default function About() {
  pageTitle('About');
  return (
    <>
      <HeroStyle5
        title="E-tiba: Who We Are"
        subTitle="Read the inspiring story of how E-tiba was founded and explore image galleries of some of our staff and events."
        imgUrl="/images/about/banner.jpg"
        bgUrl="/images/home_1/cta_bg.svg"
      />
      <Section topMd={170} topLg={120} topXl={80}>
        <div className="container d-flex justify-content-center align-items-center">
          <div className="col-lg-8">
            <SectionHeading title="Our Story" center />
            <Spacing md="30" lg="50" />
            <p className="cs_black_color">
              At E-tiba Home Healthcare, we understand the importance of quality care for your loved ones. Our company
              was
              founded by Loise, a registered nurse who witnessed firsthand the difficulties families face when caring
              for
              aging or disabled family members. <br/><br/>

              Loise saw the need for compassionate and reliable in-home care and decided to make it her life’s work to
              provide families with the support they need. She started E-tiba Home Healthcare with a small team of
              dedicated caregivers, and the company has since grown to serve families across the region. <br/><br/>

              Our team at E-tiba is made up of over 200 skilled and compassionate professionals who are committed to
              providing the highest level of care. We understand that each family has unique needs, and we work closely
              with our clients to develop personalized care plans that address those needs.<br/><br/>

              We believe that in-home care is the best option for those who want to maintain their independence and
              dignity while receiving the care they need. Our caregivers are trained to provide a wide range of
              services,
              including nursing care, medication management, meal preparation, transportation, and personal
              care.<br/><br/>

              At E-tiba we know that trust is essential in any caregiver relationship. That’s why we carefully screen
              all
              of our employees and provide extensive training to ensure that they meet our high standards of
              professionalism and excellence.<br/><br/>

              We are proud of the work we do and we are committed to providing exceptional care to our clients and their
              families. Our mission is to help our clients live their best lives possible, and we strive to achieve that
              goal every day.<br/><br/>

              If you’re looking for compassionate and reliable in-home care for your loved one, we invite you to learn
              more about us and how we can help.<br/><br/>

              Contact us today to schedule a consultation with one of our care coordinators.<br/>
            </p>
          </div>
        </div>
      </Section>
      <Section topMd={170} topLg={120} topXl={80}>
        <GallerySection
            sectionTitle="Our Staff and <br />Latest Activities"
            sectionTitleUp="HAVE A LOOK AT"
            data={galleryData}
        />
      </Section>

    </>
  );
}
