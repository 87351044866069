import React from 'react';
import ContactForm from '../ContactForm';
import Section from '../Section';
import ContactInfoSection from '../Section/ContactInfoSection';
import { pageTitle } from '../../helpers/PageTitle';
import HeroStyle5 from "../Hero/HeroStyle5";

export default function Contact() {
  pageTitle('Contact');
  return (
    <>
      <HeroStyle5
        title="Contact Us"
        subTitle="We are passionate about providing excellent customer service. If you have any questions or feedback about our services, feel free to reach out to us."
        imgUrl="/images/contact/banner.svg"
        bgUrl="/images/home_1/cta_bg.svg"
      />
      <div className="container cs_mt_minus_110">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <ContactForm />
          </div>
        </div>
      </div>
      <Section
        topMd={200}
        topLg={150}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <ContactInfoSection sectionTitle="Find Us Here" />
      </Section>
    </>
  );
}
