import React from 'react';
import AboutSection from '../Section/AboutSection';
import Banner from '../Section/BannerSection';
import Section from '../Section';
import FeaturesSection from '../Section/FeaturesSection';
import TestimonialSection from '../Section/TestimonialSection';
import AppointmentSection from '../Section/AppointmentSection';
import AwardSection from '../Section/AwardSection';
import { pageTitle } from '../../helpers/PageTitle';
import HeroStyle5 from "../Hero/HeroStyle5";
const featureListData = [
  {
    iconSrc: '/images/home_1/compassion.svg',
    title: 'Compassion',
    subTitle:
      'We understand that seeking medical care can be a stressful and emotional experience, and we strive to create a welcoming and supportive environment that puts our patients at ease.',
  },
  {
    iconSrc: '/images/home_1/excellence.svg',
    title: 'Excellence',
    subTitle:
      'We are committed to providing excellent medical care and services to our patients. We believe in continuously improving our skills, knowledge, and resources to ensure that we deliver the highest quality care possible.',
  },
  {
    iconSrc: '/images/home_1/integrity.svg',
    title: 'Integrity',
    subTitle: `We believe in practicing medicine with integrity and honesty. We are transparent in our communication and decision-making processes, and we always put our patient's interests first & provide best solution.`,
  },
  {
    iconSrc: '/images/home_1/respect.svg',
    title: 'Experienced Staff',
    subTitle:
      'Well trained and highly skilled health professionals',
  },
  {
    iconSrc: '/images/home_1/teamwork.svg',
    title: 'Convenience and Flexibility',
    subTitle:
      'We offer flexible scheduling and convenient care options to fit your lifestyle',
  },
];

const awardData = [
  {
    title: 'Medical Consultation',
    subTitle:
      'Get professional medical consultation from the comfort of your home with our expert healthcare team. Our virtual consultations are convenient, secure, and personalized to meet your unique healthcare needs.',
    iconUrl: 'images/services/medical-consultation.png',
  },
  {
    title: 'Nursing Services',
    subTitle:
      'We provide top-quality care for patients in the comfort of their own homes including critical care. Our skilled team of nurses provides compassionate, personalized care to ensure optimal health and wellbeing.',
    iconUrl: 'images/services/nursing-services.png',
  },
  {
    title: 'Assisted Living',
    subTitle:
      'Trust us to provide a safe and comfortable home away from home. This includes helping a patient with chores, meal preparation, laundry, and other housekeeping items to enable them to stay safe at home.',
    iconUrl: 'images/services/assisted-living.png',
  },
  {
    title: 'Therapy',
    subTitle:
      'Looking for professional therapy services for yourself or a loved one? We offer a variety of therapeutic services including physiotherapy, speech and occupational therapies to help you improve and recover from an illness',
    iconUrl: 'images/services/therapy.png',
  },
  {
    title: 'General Health Procedures',
    subTitle:
        'At E-TIBA we provide a wide range of medical procedures ranging from drug administration, wound care, catheterization, colostomy care, pap-smear and many more!',
    iconUrl: 'images/services/general-health-procedures.png',
  },
  {
    title: 'Mental HealthCare',
    subTitle:
        'Our mental healthcare services provide compassionate and evidence-based treatment for individuals struggling with a variety of mental health concerns. Find the support you need to improve your mental well-being today.',
    iconUrl: 'images/services/mental-health.png',
  },
  {
    title: 'Baby Wellness',
    subTitle:
        'Our baby wellness services prioritize your child’s health and development with expert care and support for their overall well-being. Trust us to provide the best home healthcare for your little one.',
    iconUrl: 'images/services/baby-wellness.png',
  },
  {
    title: 'Nutrition & Dietics',
    subTitle:
        'Our registered dietitians work with you to develop a nutrition plan that fits your unique needs and goals, helping you achieve optimal health from the comfort of your home.',
    iconUrl: 'images/services/nutrition.png',
  },
  {
    title: 'School-Based Care',
    subTitle:
        'We provide medical services and support to students in their schools to ensure their safety and wellbeing including health-talks and fitness exams for those engaged in sports.',
    iconUrl: 'images/services/school-based-care.png',
  },
  {
    title: 'Sexual Wellness',
    subTitle:
        'Our sexual wellness services offer confidential and compassionate care to support individuals of all genders and orientations in achieving healthy and fulfilling sexual lives.',
    iconUrl: 'images/services/sexual-wellness.png',
  },
  {
    title: 'Laboratory Tests',
    subTitle:
        'We provide comprehensive diagnostic testing from the comfort of your own home. Our experienced and licensed phlebotomists collect samples and deliver accurate results promptly. Trust us for all your laboratory needs.',
    iconUrl: 'images/services/lab-tests.png',
  },
  {
    title: 'Pharmacy',
    subTitle:
        'We provide reliable medication delivery, prescription management, and medication education to ensure proper use and optimal health outcomes.',
    iconUrl: 'images/services/pharmacy.png',
  },
  {
    title: 'Medical Equipment',
    subTitle:
        'Browse a wide selection of medical equipment supplies to help you manage your health at home. From mobility aids to respiratory equipment, our high-quality products are designed to improve your quality of life and enhance your independence.',
    iconUrl: 'images/services/medical-equipment.png',
  },
  {
    title: 'Ambulance',
    subTitle:
        'We provide quality and timely pre-hospital medical care and ambulance services, ensuring seriously ill and injured patients are stabilized and treated soon after.',
    iconUrl: 'images/services/ambulance.png',
  },
  {
    title: 'Training Services & Patient Education',
    subTitle:
        'Our training covers a range of topics, including medication management, personal care, communication, and safety, to give you peace of mind knowing your loved one is in capable hands. We offer online and physical courses with certificates of completion. Register Today!',
    iconUrl: 'images/services/training.png',
  },
];

export default function Home() {
  pageTitle('Home');
  return (
    <>
      <HeroStyle5
          title="Convenient Home Healthcare Services"
          subTitle="E-tiba is a digital medical platform designed to connect patients to caregivers. Accessing home-based healthcare has never been easier. With the simplest click of a button, you can now request health services online and get a registered medical professional at your doorstep within minutes."
          imgUrl="/images/home_1/hero_img_cropped.png"
          btnText="Book An Appointment"
          btnUrl="/appointments"
          bgUrl="/images/home_1/cta_bg.svg"
          infoList={[
            {
              title: 'Hotline',
              subTitle: '0783 119 121 ',
              iconUrl: '/images/contact/icon_1.svg',
            }
          ]}
          funfactList={[
            { number: '20+', title: 'Years of experience' },
            { number: '95%', title: 'Patient satisfaction rating' },
            { number: '5000+', title: 'Patients served annually' },
            { number: '10+', title: 'Healthcare providers on staff' },
          ]}
      />

      {/* Start Feature Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Why Choose Us" data={featureListData} />
      </Section>
      {/* End Feature Section */}
      {/* Start About Section */}
      <Section>
        <AboutSection
          imgUrl="/images/home_1/about.jpeg"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="About Us"
          featureList={[
            {
              featureListTitle:
                'ProHealth is a team of experienced medical professionals',
              featureListSubTitle:
                'At Etiba, we are committed to providing high-quality and compassionate care to individuals in the comfort of their own homes. We understand that every patient has unique needs and preferences, and we strive to deliver personalized care that meets those needs. The last 3 years has seen us serve over 300 families in Kenya, and we are proud to be a trusted provider of home healthcare services in the region. Our team of skilled professionals includes registered nurses, physical therapists, occupational therapists, and other healthcare experts who are dedicated to delivering exceptional care to our patients',
            },
          ]}
        />
      </Section>
      {/* End About Section */}

      {/* Start Services Section */}
      <Section topMd={185} topLg={140} topXl={100}>
        <AwardSection sectionTitle="Our Services" data={awardData} />
      </Section>
      {/* End Award Section */}
      {/* Start Testimonial */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Some Reviews"
          sectionTitleDown="Of our clients"
        />
      </Section>
      {/* End Testimonial */}
      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/home_1/cta_img.png"
          title="Don’t Let Your Health Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced medical professionals today!"
        />
      </Section>
      {/* End Banner Section */}

      {/* End Blog Section */}
      {/* Start Appointment Section */}
      <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
          imgUrl="/images/home_1/appointment.avif"
        />
      </Section>
      {/* End Appointment Section */}
    </>
  );
}
