import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';

export default function SocialWidget() {
  return (
    <div className="cs_social_links_wrap">
      <h2>Follow Us</h2>
      <div className="cs_social_links">
        <Link to="https://www.facebook.com/etibaLLC?mibextid=ZbWKwL">
          <Icon icon="fa-brands:facebook-f" />
        </Link>
        <Link to="https://www.linkedin.com/company/etiba-home-health/">
          <Icon icon="fa-brands:linkedin-in" />
        </Link>
        <Link to="https://twitter.com/EtibaHomehealth?t=V5ZArue-GoC4KRvw7Z8Ivg&s=09">
          <Icon icon="fa-brands:twitter" />
        </Link>
        <Link to="https://www.instagram.com/etibahomecare/">
          <Icon icon="fa-brands:instagram" />
        </Link>
      </div>
    </div>
  );
}
