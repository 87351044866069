import React from 'react';
import AppointmentWithContactInfoSection from '../Section/AppointmentWithContactInfoSection';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
import HeroStyle5 from "../Hero/HeroStyle5";

export default function Appointments() {
  pageTitle('Appointments');
  return (
    <>
      <HeroStyle5
        title="Don’t Let Your Health Take a Backseat!"
        subTitle="Fill out the appointment form below to schedule a consultation with one of our healthcare professionals."
        imgUrl="/images/home_1/appointment.avif"
        bgUrl="/images/home_1/cta_bg.svg"
      />
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <AppointmentWithContactInfoSection />
      </Section>
    </>
  );
}
