import React from 'react';
import BannerSectionStyle4 from '../Section/BannerSection/BannerSectionStyle4';
import TeamSectionStyle2 from '../Section/TeamSection/TeamSectionStyle2';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
import HeroStyle5 from "../Hero/HeroStyle5";
const teamData = [
  {
    imgUrl: '/images/icons/avatar.jpg',
    department: 'Nutrition',
    name: 'Dr. Patricia Omondi, MD',
    designation: 'Nutrition Specialist',
    description:
      'Dr. Patricia has over 10 years of experience in nutrition medicine and is board certified in nutrition medicine. She is board-certified in nutritional medicine and has a special interest in dietetics.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'nutrition',
    href: '/doctors/doctor-details',
  },
  {
    imgUrl: '/images/icons/avatar.jpg',
    department: 'Emergency Department',
    name: 'Dr. Michael Kimani, MD',
    designation: 'Emergency Medicine Specialist',
    description:
      'Dr. Michael is a highly experienced emergency medicine physician with over 15 years of experience. He is board-certified in emergency medicine and has a special interest in disaster management.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'emergency',
    href: '/doctors/doctor-details',
  },
  {
    imgUrl: '/images/icons/avatar.jpg',
    department: 'Pediatric Department',
    name: 'Dr. Karen Kioko, MD',
    designation: 'Pediatric Specialist',
    description:
      'Dr. Karen is a skilled psychiatrist with expertise in the treatment of a wide variety of mental health conditions. She is board-certified in emergency medicine and has a special interest in mental welgfare',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'pediatric',
    href: '/doctors/doctor-details',
  }
];

export default function Doctors() {
  pageTitle('Doctors');
  return (
    <>
      <HeroStyle5
        title="Meet Our Experts"
        subTitle="Explore the profiles of our healthcare professionals."
        imgUrl="/images/doctors/banner_img_4.png"
        bgUrl="/images/home_1/cta_bg.svg"
      />
      <Section topMd={65} bottomMd={200} bottomLg={150} bottomXl={110}>
        <TeamSectionStyle2 data={teamData} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/home_1/cta_bg.svg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
        />
      </Section>
    </>
  );
}
