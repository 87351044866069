import React from "react";
import {Link} from "react-router-dom";

export default function WhatsAppIcon() {
  const phoneNumber = "+254740658994"; // Replace with your phone number
  const message = "Hello, I would like to book an appointment.";

  return (
    <Link
      to={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`}
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-fab"
    >
      <i className="fab fa-whatsapp"></i>
    </Link>
  );
}
